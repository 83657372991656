#error-page {
  text-align: center;
  transform: translate(0, 200%);
}

.home-button a {
  color: #ffffff;
  text-decoration: none;
  font-size: 1.2em;
  text-transform: uppercase;
  font-weight: 500;
  padding: 15px 15px;
  width: 100%;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  outline: #000000 solid 1px;
  background-color: #6d9af9;
  border-radius: 25px;
}
.home-button a:hover {
  background-color: #e96140;
  outline: #e96140 solid 1px;
  color: white;
}
